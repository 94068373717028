module.exports = {
  home: "/",
  support: "/support/",
  features: "/#features",
  changelog: "/changelog/",
  pricing: "/#pricing",
  download: "/download",
  buyProduct: "/buy/",
  tutorials: "/dynamodb-tutorials/",
  twitter: "https://twitter.com/dynobase",
  affiliates: "https://contentforest.com/@dynobase.dev",
  memberPortal: "https://member.dynobase.dev/",
  privacyPolicy: "/privacy/",
  eula: "https://dynobase-assets.s3-us-west-2.amazonaws.com/Dynobase-EULA.pdf",
  feature: {
    importCSV: "/import-csv-to-dynamodb/",
    importJSON: "/import-json-to-dynamodb/",
    exportCSV: "/export-dynamodb-to-csv/",
    exportJSON: "/export-dynamodb-to-json/",
    sql: "/dynamodb-sql/",
    exportToS3: "/export-dynamodb-data-to-s3/",
    truncateTable: "/dynamodb-delete-all-items/",
    deleteTable: "/delete-dynamodb-table/",
    createTable: "/dynamodb-create-table/",
    deleteItem: "/delete-dynamodb-item/",
    updateItem: "/dynamodb-update-item/",
    dynamodbLocal: "/dynamodb-local-admin-gui/",
    generateCode: "/dynamodb-query/",
    conditionalUpdate: "/dynamodb-conditional-write-and-update/",
    dataModeling: "/dynamodb-data-modeling/",
    singleTableDesign: "/dynamodb-single-table-design-tool/"
  },
  learn: {
    codeExamples: "/code-examples/dynamodb-increment-counter/",
    gsi: "/dynamodb-gsi/",
    whatIs: "/what-is-dynamodb/",
    triggers: "/dynamodb-triggers/",
    streams: "/dynamodb-streams/",
    cli: "/dynamodb-cli-query-examples/",
    keys: "/dynamodb-keys/",
    faq: "/dynamodb-faq/",
    errors: "/dynamodb-errors/",
    reservedWords: "/dynamodb-reserved-words/",
    costOptimization: "/dynamodb-cost-optimization/",
    boto3: "/dynamodb-python-with-boto3/",
    nodejs: "/dynamodb-nodejs/",
    golang: "/dynamodb-golang-query-examples/",
    java: "/dynamodb-java-with-dynamodbmapper/",
    dataTypes: "/dynamodb-data-types/",
    keyboardShortcuts: "/keyboard-shortcuts/",
    tools: "/dynamodb-tools/",
    bestPractices: "/dynamodb-best-practices/",
    runLocally: "/run-dynamodb-locally/",
    capacityModes: "/dynamodb-capacity-modes/",
    awsSam: "/dynamodb-aws-sam/",
    queryExamples: "/dynamodb-query-examples/",
    globalTables: "/dynamodb-global-tables/",
    accessControl: "/dynamodb-access-control/",
    ttl: "/dynamodb-ttl/",
    transactions: "/dynamodb-transactions/",
    partiql: "/dynamodb-partiql/",
    indexes: "/dynamodb-indexes/",
    vsAurora: "/dynamodb-vs-aurora/",
    vsMongoDB: "/dynamodb-vs-mongodb/"
  },
  freeTools: {
    queryBuilder: "/dynamodb-query-builder/",
    pricingCalculator: "/dynamodb-pricing-calculator/",
    itemSizeCalculator: "/dynamodb-capacity-and-item-size-calculator/",
    tableDesigner: "/dynamodb-table-schema-design-tool/",
    jsonConverter: "/dynamodb-json-converter-tool/",
  },
}
